import React, { Component } from "react";

class Diskonto extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="sell-home-area rld-1-3-bg pd-top-100 pd-bottom-100 mg-top-50"
        style={{ backgroundImage: "url(assets/img/bg/6.png)" }}
      >
        <div className="container text-justify">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="shape-image-list-wrap">
                <img
                  className="shadow-img"
                  src={publicUrl + "assets/img/panorama_2.jpg"}
                  alt="diskonto"
                />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-title pd-left mb-0">
                <h2 className="title inner-title">
                  Asset Settlement <br />
                  Program Diskonto
                </h2>
                <p>
                  Bilyet daripada anggota akan ditukarkan dengan asset senilai
                  bilyet dengan dikurangi nilai diskonto
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Diskonto;
