import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import userService from "../../services/userService";

const ResetPassword = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().min(8).required("Field is required."),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password")], "Password must match")
        .required("Field is required."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await userService.resetPassword(props.match.params.token, values);

        MySwal.fire({
          icon: "success",
          title: "Success",
          text:
            "Password Anda berhasil diubah, silahkan login kembali dengan password baru Anda",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location = "/registration";
          }
        });
      } catch (ex) {
        setLoading(false);

        if (ex.response && ex.response.status === 400) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: ex.response.data,
          });
        }
      }
    },
  });

  return (
    <div className="register-page-area pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7 col-md-12">
            <form
              onSubmit={formik.handleSubmit}
              className="contact-form-wrap contact-form-bg needs-validation"
            >
              <div className="rld-single-input">
                <input
                  type="password"
                  placeholder="Password"
                  id="password"
                  name="password"
                  className={
                    formik.errors.password && formik.touched.password
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  required
                />

                {formik.errors.password && formik.touched.password && (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                )}
              </div>

              <div className="rld-single-input">
                <input
                  type="password"
                  placeholder="Re-enter password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  className={
                    formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  required
                />

                {formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation && (
                    <div className="invalid-feedback">
                      {formik.errors.passwordConfirmation}
                    </div>
                  )}
              </div>

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <button
                      type="submit"
                      className="btn btn-yellow btn-block"
                      onClick={formik.handleSubmit}
                      disabled={!(formik.isValid && formik.dirty) || loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <Link to="/registration">
                      <button className="btn btn-link btn-block">
                        Go back to Login Page
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
