import { useFormik } from "formik";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import authService from "../../services/authService";
import userService from "../../services/userService";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountVerification = (props) => {
  let query = useQuery();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      confirmationCode: "",
      id: query.get("id"),
    },
    validationSchema: Yup.object({
      confirmationCode: Yup.number().required("Field is required."),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await userService.accountVerification(values);

        authService.loginWithJwt(
          response.headers["x-auth-token"],
          response.data
        );
        setLoading(false);
        MySwal.fire({
          icon: "success",
          title: "Success",
          text: "Terimakasih telah melakukan pendaftaran. Silahkan untuk memilih program settlement yang Anda inginkan.",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location = "/settlement-program";
          }
        });
      } catch (ex) {
        setLoading(false);
        MySwal.fire({
          icon: "error",
          title: "Maaf",
          text: ex.response.data,
        });
      }
    },
  });

  return (
    <div className="register-page-area pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7 col-md-12">
            <form className="contact-form-wrap contact-form-bg needs-validation">
              <h4>
                Kode konfirmasi telah dikirimkan ke email Anda, silahkan cek
                email Anda
              </h4>

              <div className="rld-single-input">
                <NumberFormat
                  type="text"
                  placeholder="Masukkan kode konfirmasi"
                  id="confirmationCode"
                  name="confirmationCode"
                  allowNegative={false}
                  className={
                    formik.errors.phone && formik.touched.phone
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={formik.values.startingPrice}
                  onValueChange={(vals) => {
                    formik.setFieldValue("confirmationCode", vals.value);
                  }}
                  onBlur={formik.handleBlur}
                  required
                />

                {formik.errors.confirmationCode &&
                  formik.touched.confirmationCode && (
                    <div className="invalid-feedback">
                      {formik.errors.confirmationCode}
                    </div>
                  )}
              </div>

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <button
                      className="btn btn-yellow btn-block"
                      onClick={formik.handleSubmit}
                      disabled={!(formik.isValid && formik.dirty) || loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <button className="btn btn-link btn-block">
                      {/* Resend Verification Email */}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountVerification;
