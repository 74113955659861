import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import About from "./section-components/aboutv2";
import Bannerv4 from "./section-components/banner-v4";

const Home_V1 = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <Bannerv4 />
      <About />
      <Footer />
    </div>
  );
};

export default Home_V1;
