import React from "react";

const Development = (props) => {
  return (
    <div className="about-area">
      <div className="container mg-top-50">
        <div className="text-center pd-bottom-60">
          <h4 className="sub-title">
            Mohon maaf, halaman ini sedang dalam tahap pengembangan.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Development;
