import React, { Component } from "react";

class BannerV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL;

    const inlineStyle = {
      backgroundImage: "url(" + publicUrl + "/assets/img/banner/3.jpg)",
    };

    return (
      <div>
        <div className="banner-area jarallax" style={inlineStyle}>
          <div className="container">
            <div className="banner-inner-wrap"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerV4;
