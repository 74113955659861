import React from "react";

const Topup = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="process-area pd-top-100">
      <div className="container text-justify">
        <div className="row">
          <div className="col-lg-6 order-lg-12 mb-4 mb-lg-0">
            <div className="shape-image-list-wrap">
              <div className="shape-image-list right-top">
                <img
                  className="shadow-img"
                  src={publicUrl + "assets/img/panorama_1.jpg"}
                  alt="downpayment"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center order-lg-1">
            <div className="section-title mb-0">
              <h2 className="title inner-title">
                Asset Settlement <br />
                Program Downpayment
              </h2>
              <p>
                Bilyet daripada anggota akan digunakan sebagai downpayment dalam
                penukaran asset, dimana selisih daripada nilai asset dan bilyet
                akan dibayarkan dalam bentuk tunai
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topup;
