import React, { useEffect, useState } from "react";
import userService from "../../services/userService";

const WithoutTopup = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [userData, setUserData] = useState({});
  const { user } = props;

  useEffect(() => {
    async function getUser() {
      try {
        const { data } = await userService.getUserById();
        setUserData(data);
      } catch (ex) {}
    }

    getUser();
  }, [user.id]);

  const handleSubmit = async (option) => {
    try {
      await userService.settlementProgram({ id: user.id, option });
      window.location = "/downpayment";
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="process-area pd-top-100">
      <div className="container text-justify">
        <div className="row">
          <div className="col-lg-6 order-lg-12 mb-4 mb-lg-0">
            <div className="shape-image-list-wrap">
              <div className="shape-image-list right-top">
                <img
                  className="shadow-img"
                  src={publicUrl + "assets/img/panorama_2.jpg"}
                  alt="diskonto"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center order-lg-1">
            <div className="section-title mb-0">
              <h2 className="title inner-title">
                Asset Settlement <br />
                Program Diskonto
              </h2>
              <p>
                Bilyet daripada anggota akan ditukarkan dengan asset senilai
                bilyet dengan dikurangi nilai diskonto
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="buy-sell-area mg-top-50">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title inner-title">Hi Bapak/Ibu {user.name},</h2>
            <p className="title">
              Nomor Urut Pemesanan (NUP) Anda yaitu G{userData.nup}. Tim kami
              akan segera menghubungi Anda.
            </p>
            <hr />
            <h4 className="sub-title">
              Apakah Anda ingin menjadikan simpanan Indosurya Anda sebagai
              Downpayment untuk asset yang nilainya lebih besar ? Jika ya,
              silahkan klik button di bawah ini.
            </h4>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-author style-two text-center">
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/icons/4.png"}
                    alt="downpayment"
                  />
                </div>
                <div className="author-details">
                  <button
                    className="btn btn-yellow btn-block"
                    onClick={() => handleSubmit(2)}
                  >
                    Downpayment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithoutTopup;
