import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import companyService from "../../services/companyService";

const Mission = (props) => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    async function getSettings() {
      try {
        const { data } = await companyService.getSettingById(1);

        setSettings(data);
      } catch (ex) {}
    }

    getSettings();
  }, []);

  return (
    <div className="about-area">
      <div className="container mg-top-50">
        <div className="text-center pd-bottom-60">
          <h2 className="title">Visi</h2>
          {parse(String(settings.vision))}
        </div>

        <div className="row">
          <div className="col-lg-12 text-center pd-bottom-90">
            <h2 className="title">Misi</h2>

            {parse(String(settings.mission))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
