import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import authService from "../services/authService";
import userService from "../services/userService";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const user = authService.getCurrentUser();
  const refreshToken = authService.getRefreshToken();
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    async function getUser() {
      try {
        const { data } = await userService.getUserById();
        setUserDetail(data);
      } catch (ex) {
        console.log(ex);
      }
    }

    getUser();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/registration",
                state: { from: props.location },
              }}
            />
          );
        } else {
          if (userDetail.settlementProgram === "2" && path !== "/downpayment") {
            return (
              <Redirect
                to={{
                  pathname: "/downpayment",
                  state: { from: props.location },
                }}
              />
            );
          } else if (
            userDetail.settlementProgram === "0" &&
            path !== "/settlement-program"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/settlement-program",
                  state: { from: props.location },
                }}
              />
            );
          } else if (
            userDetail.settlementProgram === "1" &&
            path !== "/diskonto"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/diskonto",
                  state: { from: props.location },
                }}
              />
            );
          }
        }

        if (refreshToken && Date.now() >= refreshToken.exp * 1000) {
          authService.logout();
          window.location = "/registration";
        }

        if (!refreshToken) {
          authService.logout();
          window.location = "/registration";
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
