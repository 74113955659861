import http from "./httpService";
import { apiUrl } from "../config.json";

export function getSettingById(id) {
  return http.get(`${apiUrl}/v1/settings/${id}`);
}

export function getPropertyTypes(id) {
  return http.get(`${apiUrl}/v1/property-types/options`);
}

export function getLocations(id) {
  return http.get(`${apiUrl}/v1/locations/options`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSettingById,
  getPropertyTypes,
  getLocations,
};
