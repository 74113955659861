import React, { useEffect, useState } from "react";
import portfolioService from "../../services/portfolioService";

const Portfolios = (props) => {
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    async function getPortfolios() {
      try {
        const { data } = await portfolioService.getPortfolios();
        setPortfolios(data);
      } catch (ex) {}
    }

    getPortfolios();
  }, []);

  return (
    <div className="team-area bg-gray mg-top-70 pd-top-90 pd-bottom-70">
      <div className="container">
        <div className="row">
          {portfolios.map((portfolio, i) => (
            <div key={i} className="col-lg-4 col-sm-6">
              <div className="single-team">
                <div className="thumb">
                  <img
                    src={portfolio.portfolioImages[0].path}
                    alt="portfolio"
                  />
                </div>
                <div className="team-details">
                  <h4>{portfolio.name}</h4>
                  <div className="row">
                    <div className="col">
                      {/* <span>{portfolio.designation}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolios;
