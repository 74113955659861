import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Topup from "./section-components/topup";
import Preference from "./section-components/preference";

const TopupProgram = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <PageHeader
        headertitle="Program Downpayment"
        subheader="Program Downpayment"
      />
      <Topup {...props} />
      <Preference {...props} />
      <Footer />
    </div>
  );
};

export default TopupProgram;
