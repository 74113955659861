import { useFormik } from "formik";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import authService from "../../services/authService";
import userService from "../../services/userService";

const Registration = (props) => {
  let history = useHistory();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().max(255).required("Field is required."),
      password: Yup.string().required("Field is required."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let redirectPage = "/settlement-program";

        await authService.login(values);
        setLoading(false);
        const user = authService.getCurrentUser();

        if (user.settlementProgram === "0") {
          redirectPage = "/settlement-program";
        } else if (user.settlementProgram === "1") {
          redirectPage = "/diskonto";
        } else if (user.settlementProgram === "2") {
          redirectPage = "/downpayment";
        }

        window.location = redirectPage;
      } catch (err) {
        console.log(err);
        setLoading(false);

        if (err.response && err.response.status === 400) {
          MySwal.fire({
            icon: "error",
            title: "Maaf",
            text: err.response.data,
          });
        }

        if (err.response && err.response.status === 403) {
          const error = err.response.data;

          window.location = "/account-verification?id=" + error.id;
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      bilyetNo: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required("Field is required."),
      bilyetNo: Yup.string().max(255).required("Field is required."),
      email: Yup.string().email().max(255).required("Field is required."),
      phone: Yup.number().positive().integer().required("Field is required."),
      password: Yup.string().min(8).required("Field is required."),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password")], "Password must match")
        .required("Field is required."),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const { data } = await userService.addUser(values);
        setLoading(false);
        history.push("/account-verification?id=" + data.id);
      } catch (err) {
        setLoading(false);
        MySwal.fire({
          icon: "error",
          title: "Maaf",
          text: err.response.data,
        });
      }
    },
  });

  return (
    <div className="register-page-area pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-5 col-md-12 mb-5 mb-md-0">
            <form className="contact-form-wrap contact-form-bg">
              <h4>Login</h4>
              <div className="rld-single-input">
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  id="email"
                  name="email"
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  value={loginFormik.values.email}
                />
              </div>
              <div className="rld-single-input">
                <input
                  type="password"
                  placeholder="Masukkan password Anda"
                  id="password"
                  name="password"
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  value={loginFormik.values.password}
                />
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <button
                      className="btn btn-yellow btn-block"
                      onClick={loginFormik.handleSubmit}
                      disabled={!(loginFormik.isValid && loginFormik.dirty)}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <Link to="/forgot-password">
                      <button className="btn btn-link btn-block">
                        Forgot Password
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-12">
            <form
              onSubmit={formik.handleSubmit}
              className="contact-form-wrap contact-form-bg needs-validation"
            >
              <h4>Registration</h4>
              <div className="rld-single-input">
                <input
                  type="text"
                  placeholder="Masukkan nama Anda *"
                  id="name"
                  name="name"
                  className={
                    formik.errors.name && formik.touched.name
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  required
                />

                <div className="form-text">
                  <small>* Masukkan nama Anda sesuai dengan bilyet</small>
                </div>

                {formik.errors.name && formik.touched.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="rld-single-input">
                <input
                  type="text"
                  placeholder="Masukkan nomor bilyet Anda *"
                  id="bilyetNo"
                  name="bilyetNo"
                  className={
                    formik.errors.bilyetNo && formik.touched.bilyetNo
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bilyetNo}
                  required
                />

                <div className="form-text">
                  <small>
                    * Masukkan salah satu nomor bilyet yang Anda miliki. Contoh:
                    IS 00001
                  </small>
                </div>

                {formik.errors.bilyetNo && formik.touched.bilyetNo && (
                  <div className="invalid-feedback">
                    {formik.errors.bilyetNo}
                  </div>
                )}
              </div>
              <div className="rld-single-input">
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  id="email"
                  name="email"
                  className={
                    formik.errors.email && formik.touched.email
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  required
                />

                {formik.errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              <div className="rld-single-input">
                <NumberFormat
                  type="tel"
                  placeholder="Masukkan nomor telepon Anda"
                  id="phone"
                  name="phone"
                  allowLeadingZeros
                  allowNegative={false}
                  className={
                    formik.errors.phone && formik.touched.phone
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={formik.values.startingPrice}
                  onValueChange={(vals) => {
                    formik.setFieldValue("phone", vals.value);
                  }}
                  onBlur={formik.handleBlur}
                  required
                />

                {formik.errors.phone && formik.touched.phone && (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                )}
              </div>
              <div className="rld-single-input">
                <input
                  type="password"
                  placeholder="Password"
                  id="password"
                  name="password"
                  className={
                    formik.errors.password && formik.touched.password
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  required
                />

                {formik.errors.password && formik.touched.password && (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div className="rld-single-input">
                <input
                  type="password"
                  placeholder="Re-enter password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  className={
                    formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  required
                />

                {formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation && (
                    <div className="invalid-feedback">
                      {formik.errors.passwordConfirmation}
                    </div>
                  )}
              </div>
              <div className="btn-wrap">
                <button
                  type="submit"
                  className="btn btn-yellow"
                  onClick={formik.handleSubmit}
                  disabled={!(formik.isValid && formik.dirty) || loading}
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
