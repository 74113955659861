import http from "./httpService";
import { apiUrl } from "../config.json";

export function getPortfolios(id) {
  return http.get(`${apiUrl}/v1/portfolios`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPortfolios,
};
