import React, { useEffect, useState } from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import companyService from "../services/companyService";

const Contact = (props) => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    async function getSettings() {
      try {
        const { data } = await companyService.getSettingById(1);

        setSettings(data);
      } catch (ex) {}
    }

    getSettings();
  }, []);

  return (
    <div>
      <Navbar {...props} />
      <PageHeader headertitle="Contact Us" />
      <div className="contact-area pd-top-100 pd-bottom-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-page-map">
                <iframe
                  title="Location"
                  className="w-100"
                  src={settings.googleMapLocation}
                  style={{ border: 0 }}
                  allowFullScreen
                />
              </div>
              <br />
            </div>
            <div className="col-lg-4">
              {/* <div className="single-contact-info">
                <p>
                  <i className="fa fa-phone" />
                  Telepon
                </p>
                <h5>{settings.companyPhone}</h5>
              </div> */}
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-envelope" />
                  Email
                </p>
                <h5>{settings.companyEmail}</h5>
              </div>
              <div className="single-contact-info text-justify">
                <p>
                  <i className="fa fa-map-marker" />
                  Alamat
                </p>
                <h5>{settings.companyAddress}</h5>
              </div>
              {/* <div className="single-contact-info">
                <div className="btn-wrap text-center">
                  <a
                    className="btn btn-success"
                    href={`https://web.whatsapp.com/send?phone=${settings.companyPhone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-whatsapp" />
                    &nbsp;Whatsapp
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
