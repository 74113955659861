import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Truncate from "react-truncate";
import companyService from "../../services/companyService";

const AboutV2 = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [settings, setSettings] = useState([]);
  useEffect(() => {
    // let publicUrl = process.env.PUBLIC_URL;
    // const minscript = document.createElement("script");
    // minscript.async = true;
    // minscript.src = publicUrl + "/assets/js/main.js";

    // document.body.appendChild(minscript);

    async function getSettings() {
      try {
        const { data } = await companyService.getSettingById(1);

        setSettings(data);
      } catch (ex) {}
    }

    getSettings();
  }, []);

  return (
    <div className="about-area pd-top-100 pd-bottom-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="section-title mb-lg-0">
              <h2
                className="title"
                dangerouslySetInnerHTML={{
                  __html: "About <br/>Sun International Capital",
                }}
              ></h2>
              <div className="text-justify">
                <Truncate lines={4} trimWhitespace>
                  {parse(String(settings.aboutUs))}
                </Truncate>
              </div>
              <Link className="btn-view-all-2" to="/about">
                View More <i className="la la-long-arrow-right" />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="shape-image-list-wrap">
              <div className="shape-image-list left-top">
                <img
                  className="shadow-img"
                  src={publicUrl + "assets/img/indosurya_tower.png"}
                  alt="indosurya_tower"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV2;
