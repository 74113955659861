import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import userService from "../../services/userService";

const ForgotPassword = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().max(255).required("Field is required."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await userService.forgotPassword(values);
        setLoading(false);

        MySwal.fire({
          icon: "success",
          title: "Success",
          text:
            "Email telah dikirim ke alamat email Anda. Ikuti petunjuk dalam email untuk mengatur ulang password Anda.",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location = "/registration";
          }
        });
      } catch (ex) {
        console.log(ex);
        setLoading(false);

        if (ex.response && ex.response.status === 400) {
          MySwal.fire({
            icon: "error",
            title: "Maaf",
            text: ex.response.data,
          });
        }
      }
    },
  });

  return (
    <div className="register-page-area pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7 col-md-12">
            <form className="contact-form-wrap contact-form-bg needs-validation">
              <div className="rld-single-input">
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <button
                      className="btn btn-yellow btn-block"
                      onClick={formik.handleSubmit}
                      disabled={!(formik.isValid && formik.dirty) || loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="btn-wrap">
                    <Link to="/registration">
                      <button className="btn btn-link btn-block">
                        Go back to Login Page
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
