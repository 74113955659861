import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import WithoutTopup from "./section-components/without-topup";

const WithoutTopupProgram = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <PageHeader headertitle="Program Diskonto" subheader="Program Diskonto" />
      <WithoutTopup {...props} />
      <Footer />
    </div>
  );
};

export default WithoutTopupProgram;
