import React, { useEffect, useState } from "react";
import footerdata from "../../data/footerdata.json";
import companyService from "../../services/companyService";

const Footer_v1 = (props) => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL;
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "/assets/js/main.js";

    document.body.appendChild(minscript);

    async function getSettings() {
      try {
        const { data } = await companyService.getSettingById(1);

        setSettings(data);
      } catch (ex) {}
    }

    getSettings();
  }, []);

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <div className="widget widget_nav_menu">
              <h4 className="widget-title">Contact Us</h4>
              <ul>
                <li className="readeal-top">
                  <i className="fa fa-map-marker" /> {settings.companyAddress}
                </li>
                {/* <li className="readeal-top">
                  <i className="fa fa-phone" /> {settings.companyPhone}
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="widget widget_nav_menu">
              <h4 className="widget-title">&nbsp;</h4>
              <ul>
                <li className="readeal-top">
                  <i className="fa fa-map-marker" /> {settings.companyAddress2}
                </li>
                <li className="readeal-top">
                  <i className="fa fa-phone" /> {settings.companyPhone2}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="widget widget_nav_menu">
              <h4 className="widget-title">&nbsp;</h4>
              <ul>
                <li className="readeal-top">
                  <i className="fa fa-map-marker" /> SUN Tower, Jl. Boulevard
                  Gading Serpong, Blok M No. 19 Sumarecon Serpong
                </li>
                {/* <li className="readeal-top">
                  <i className="fa fa-phone" /> {settings.companyPhone2}
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-6 col-sm-12">
            <div className="widget widget_nav_menu">
              <h4 className="widget-title">{footerdata.quicklink.title}</h4>
              <ul>
                {footerdata.quicklink.links.map((item, i) => (
                  <li className="readeal-top" key={i}>
                    <Link to={item.url}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-sm-6">
            <div className="widget">
              <h4 className="widget-title">Follow Us</h4>
              <div className="footer-social">
                <ul className="social-icon">
                  <li>
                    <a
                      href={settings.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>

                  <li>
                    <a
                      href={settings.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
        <hr />
        <div
          className="copy-right text-center"
          dangerouslySetInnerHTML={{ __html: footerdata.copyrighttext }}
        ></div>
      </div>
    </footer>
  );
};

export default Footer_v1;
