import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ForgotPasswordSection from "./section-components/forgot-password";

const ForgotPassword = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Forgot Password" subheader="Forgot Password" />
      <ForgotPasswordSection />
      <Footer />
    </div>
  );
};

export default ForgotPassword;
