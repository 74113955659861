import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import companyService from "../../services/companyService";

const AboutUs = (props) => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    async function getSettings() {
      try {
        const { data } = await companyService.getSettingById(1);

        setSettings(data);
      } catch (ex) {}
    }

    getSettings();
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="about-area mg-top-50 pd-bottom-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="shape-image-list-wrap">
              <div className="shape-image-list left-top">
                <img
                  className="shadow-img"
                  src={publicUrl + "assets/img/indosurya_tower.png"}
                  alt="indosurya_tower"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="section-title pd-left mb-0 text-justify">
              <h2 className="title">Sun International Capital</h2>
              {parse(String(settings.aboutUs))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
