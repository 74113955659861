import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Development from "./section-components/development";

const ReadyStock = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <PageHeader headertitle="Ready Stock" />
      <Development />
      <Footer />
    </div>
  );
};

export default ReadyStock;
