import React from "react";
import { Link } from "react-router-dom";
import authService from "../../services/authService";

const Navbar = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { user } = props;

  const handleLogout = () => {
    authService.logout();
    window.location = "/";
  };

  return (
    <div>
      <div className="navbar-area">
        <nav className="navbar navbar-area navbar-expand-lg">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button
                className="menu toggle-btn d-block d-lg-none"
                data-toggle="collapse"
                data-target="#realdeal_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link to="/">
                <img
                  src={publicUrl + "assets/img/logo.png"}
                  alt="logo"
                  width={225}
                />
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="realdeal_main_menu">
              <ul className="navbar-nav menu-open readeal-top">
                <li className="current-menu-item">
                  <Link to="/">HOME</Link>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">PROJECTS</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/ready-stock">Ready Stock</Link>
                    </li>
                    <li>
                      <Link to="/upcoming-project">Upcoming Projects</Link>
                    </li>
                    <li>
                      <Link to="/settlement-program">
                        Asset Settlement Program
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">CONTACT US</Link>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">PROFILE</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/vission-mission">Vission &amp; Mission</Link>
                    </li>
                    <li>
                      <Link to="/our-portfolio">Our Portfolio</Link>
                    </li>
                  </ul>
                </li>
                {user && (
                  <li>
                    <Link to="/contact" onClick={handleLogout}>
                      LOGOUT
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
