import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import userService from "../../services/userService";
import companyService from "../../services/companyService";

const Preference = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const { user } = props;
  const MySwal = withReactContent(Swal);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    async function getPropertyTypes() {
      try {
        const { data } = await companyService.getPropertyTypes();

        setPropertyTypes(data);
      } catch (ex) {}
    }

    getPropertyTypes();

    async function getLocations() {
      try {
        const { data } = await companyService.getLocations();

        setLocations(data);
      } catch (ex) {}
    }

    getLocations();
  }, []);

  const preferenceFormik = useFormik({
    initialValues: {
      propertyType: [],
      location: [],
      otherLocation: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await userService.addPreference({
          id: user.id,
          location: values.location,
          propertyType: values.propertyType,
          otherLocation: values.otherLocation,
        });

        MySwal.fire({
          icon: "success",
          title: "Success",
          text:
            "Terimakasih telah mengisi tipe dan lokasi properti yang Anda inginkan, tim kami akan segera menghubungi Anda",
        });
      } catch (err) {
        MySwal.fire({
          icon: "error",
          title: "Maaf",
          text: err.response.data,
        });
      }
    },
  });

  const handleSubmitProgram = async (option) => {
    try {
      await userService.settlementProgram({ id: user.id, option });
      window.location = "/diskonto";
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="process-area pd-bottom-90">
      <div className="container text-justify">
        <div className="row">
          <div className="col-lg-6  mb-4 mb-lg-0">
            <form
              onSubmit={preferenceFormik.handleSubmit}
              className="contact-form-wrap contact-form-bg needs-validation"
            >
              <h4>Preferensi Anda</h4>
              <h6>
                Silahkan untuk mengisi terlebih dahulu tipe properti dan lokasi
                yang Anda inginkan.
              </h6>

              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  1. Tipe Properti yang Anda inginkan
                </label>

                {propertyTypes.map((p, index) => (
                  <div key={`propType_${index}`} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={p.value}
                      name="propertyType"
                      value={p.value}
                      onChange={preferenceFormik.handleChange}
                    />
                    <label className="form-check-label" htmlFor={p.value}>
                      &nbsp;{p.label}
                    </label>
                  </div>
                ))}
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  2. Lokasi Properti yang Anda inginkan
                </label>

                {locations.map((l, index) => (
                  <div key={`location_${index}`} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={l.value}
                      name="location"
                      value={l.value}
                      onChange={preferenceFormik.handleChange}
                    />
                    <label className="form-check-label" htmlFor={l.value}>
                      &nbsp;{l.label}
                    </label>
                  </div>
                ))}

                <div className="form-check">
                  <div className="rld-single-input">
                    <input
                      type="text"
                      placeholder="Sebutkan kota lainnya"
                      id="otherLocation"
                      name="otherLocation"
                      onChange={preferenceFormik.handleChange}
                      onBlur={preferenceFormik.handleBlur}
                      value={preferenceFormik.values.otherCity}
                    />
                  </div>
                </div>
              </div>

              <div className="btn-wrap">
                <button
                  type="submit"
                  className="btn btn-yellow"
                  onClick={preferenceFormik.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          <div className="col-lg-6 align-self-center order-lg-1">
            <div className="container">
              <div className="section-title text-lg-center">
                <h2 className="title inner-title">Hi Bapak/Ibu {user.name},</h2>
                <h4 className="sub-title">
                  Apakah Anda ingin simpanan Anda didiskonto dengan asset ? Jika
                  ya, silahkan klik button di bawah ini.
                </h4>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-sm-6">
                  <div className="single-author style-two text-center">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/icons/27.png"}
                        alt={imagealt}
                      />
                    </div>
                    <div className="author-details">
                      <button
                        className="btn btn-yellow btn-block"
                        onClick={() => handleSubmitProgram(1)}
                      >
                        Diskonto
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preference;
