import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import About from "./components/about";
import AccountVerification from "./components/account-verification";
import Contact from "./components/contact";
import Error from "./components/error";
import ForgotPassword from "./components/forgot-password";
import HomeV4 from "./components/home-v4";
import Mission from "./components/mission";
import Portfolio from "./components/portfolio";
import ProtectedRoute from "./components/ProtectedRoute";
import ReadyStock from "./components/ready-stock";
import Registration from "./components/registration";
import ResetPassword from "./components/reset-password";
import SettlementProgram from "./components/settlement-program";
import TopUpProgram from "./components/topup-program";
import UpcomingProject from "./components/upcoming-project";
import WithoutTopUpProgram from "./components/without-topup-program";
import authService from "./services/authService";

export function App() {
  const [user, setUser] = useState({});
  const refreshToken = authService.getRefreshToken();

  useEffect(() => {
    const user = authService.getCurrentUser();
    setUser(user);
  }, []);

  if (refreshToken && Date.now() >= refreshToken.exp * 1000) {
    authService.logout();
    window.location = "/registration";
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <HomeV4 {...props} user={user} />}
        />

        <Route
          path="/ready-stock"
          render={(props) => <ReadyStock {...props} user={user} />}
        />

        <Route
          path="/upcoming-project"
          render={(props) => <UpcomingProject {...props} user={user} />}
        />

        <Route
          path="/about"
          render={(props) => <About {...props} user={user} />}
        />

        <Route
          path="/vission-mission"
          render={(props) => <Mission {...props} user={user} />}
        />

        <Route
          path="/our-portfolio"
          render={(props) => <Portfolio {...props} user={user} />}
        />

        <Route
          path="/registration"
          render={(props) => <Registration {...props} />}
        />

        <Route path="/error" component={Error} />

        <Route
          path="/contact"
          render={(props) => <Contact {...props} user={user} />}
        />

        <Route
          path="/account-verification"
          render={(props) => <AccountVerification {...props} user={user} />}
        />

        <Route
          path="/forgot-password"
          render={(props) => <ForgotPassword {...props} user={user} />}
        />

        <Route
          path="/reset-password/:token"
          render={(props) => <ResetPassword {...props} user={user} />}
        />

        <ProtectedRoute
          path="/downpayment"
          render={(props) => <TopUpProgram {...props} user={user} />}
        />

        <ProtectedRoute
          path="/diskonto"
          render={(props) => <WithoutTopUpProgram {...props} user={user} />}
        />

        <ProtectedRoute
          path="/settlement-program"
          exact
          render={(props) => <SettlementProgram {...props} user={user} />}
        />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
