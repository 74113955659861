import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Mission from "./section-components/mission";

const About = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <PageHeader headertitle="Vission &amp; Mission" />
      <Mission />
      <Footer />
    </div>
  );
};

export default About;
