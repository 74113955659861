import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ResetPasswordSection from "./section-components/reset-password";

const ResetPassword = (props) => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Reset Password" subheader="Reset Password" />
      <ResetPasswordSection {...props} />
      <Footer />
    </div>
  );
};

export default ResetPassword;
