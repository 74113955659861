import React from "react";
import userService from "../../services/userService";

const DownpaymentOrDiskonto = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const { user } = props;

  const handleSubmit = async (option) => {
    try {
      await userService.settlementProgram({ id: user.id, option });
      if (option === "2") {
        window.location = "/downpayment";
      } else {
        window.location = "/diskonto";
      }
    } catch (ex) {}
  };

  return (
    <div className="buy-sell-area pd-bottom-70 mg-top-50">
      <div className="container">
        <div className="section-title text-lg-center">
          <h2 className="title inner-title">Hi, {user.name}</h2>
          <h4 className="sub-title">
            Apakah Anda memilih untuk menyelesaikan simpanan Indosurya dengan
            asset settlement ?
          </h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="single-author style-two text-center">
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/icons/4.png"}
                  alt="downpayment"
                />
              </div>
              <div className="author-details">
                <button
                  className="btn btn-yellow btn-block"
                  onClick={() => handleSubmit(2)}
                >
                  Downpayment
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div className="single-author style-two text-center">
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/icons/27.png"}
                  alt="diskonto"
                />
              </div>
              <div className="author-details">
                <button
                  className="btn btn-yellow btn-block"
                  onClick={() => handleSubmit(1)}
                >
                  Diskonto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownpaymentOrDiskonto;
