import http from "./httpService";
import { apiUrl } from "../config.json";

export function addUser(data) {
  return http.post(`${apiUrl}/v1/members`, data);
}

export function accountVerification(user) {
  return http.post(`${apiUrl}/v1/members/account-verification`, user);
}

export function forgotPassword(data) {
  return http.post(`${apiUrl}/v1/members/forgot-password`, data);
}

export function resetPassword(token, data) {
  return http.put(`${apiUrl}/v1/members/reset-password/${token}`, data);
}

export function settlementProgram(data) {
  return http.post(`${apiUrl}/v1/members/settlement-program`, data);
}

export function addPreference(data) {
  return http.post(`${apiUrl}/v1/members/preference`, data);
}

export function getUserById() {
  return http.get(`${apiUrl}/v1/members/me`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addUser,
  getUserById,
  accountVerification,
  settlementProgram,
  addPreference,
  forgotPassword,
  resetPassword,
};
