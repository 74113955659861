import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Diskonto from "./section-components/diskonto";
import Topup from "./section-components/topup";
import DownpaymentOrDiskonto from "./section-components/downpayment-or-diskonto";
import Footer from "./global-components/footer";

const SettlementProgram = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <PageHeader
        headertitle="Asset Settlement Program"
        subheader="Asset Settlement Program"
      />
      <Topup />
      <Diskonto />
      <DownpaymentOrDiskonto {...props} />
      <Footer />
    </div>
  );
};

export default SettlementProgram;
