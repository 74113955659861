import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import AccountVerificationSection from "./section-components/account-verification";

const AccountVerification = () => {
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Account Verification"
        subheader="Account Verification"
      />
      <AccountVerificationSection />
      <Footer />
    </div>
  );
};

export default AccountVerification;
