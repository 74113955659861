import React from "react";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Portfolios from "./section-components/portfolios";

const Portfolio = (props) => {
  return (
    <div>
      <Navbar {...props} />
      <PageHeader headertitle="Our Portfolio" />
      <Portfolios />
      <Footer />
    </div>
  );
};

export default Portfolio;
